<template>

  <mobile-float-menu header>
    <template v-slot:content>
      <div class="container-fluid ">
        <LanguageLoader routeLanguage="./translations/promotions/promotions_" />
      </div>
    </template>
  </mobile-float-menu>

</template>

<script>
  import MobileFloatMenu from "@/components/MobileFloatMenu";
  import LanguageLoader from '@/components/TranslationsLoader.vue'
  export default {
    components: {
      LanguageLoader,
      MobileFloatMenu,
    },
    methods: {},
    mounted() {}
  }
</script>

<style>
</style>